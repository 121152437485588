<template lang="pug">
  .vue-pouvoir-possel
    copie-pouvoir-en-cours(v-if="solved" names="de possel")
    div(v-else)
      img.w-100.p-absolute.bg(
        src="@/assets/pouvoirs/de-possel/fond.jpg"
        srcset="@/assets/pouvoirs/de-possel/fond@2x.jpg 2x")
      menu-button
      .text-block.p-absolute.titre(v-html="$t('pouvoirs.de-possel.titre')")
      .text-block.p-absolute.progress {{$t('pouvoirs.de-possel.progression', { value: solvedCount})}}

      div(v-if="headIndex === 0")
        tete1.tete(v-on:select="selectHeadElement")
      div(v-if="headIndex === 1")
        tete3.tete(v-on:select="selectHeadElement")

      .selection(:class="{ error }")
        .selection-item(v-for="el in head.selected")
          img(:src="require(`@/assets/pouvoirs/de-possel/tete_0${head.index}_${el}.png`)")
      button.p-absolute.text-block.clear-button(
        :class="{ hidden: head.selected.length === 0}"
        @click="clearSelection()") {{$t('pouvoirs.de-possel.bouton-effacer')}}

      img.personnage-ok(
        v-if="personnageOk"
        src="@/assets/pouvoirs/de-possel/pouce.png"
        srcset="@/assets/pouvoirs/de-possel/pouce@2x.png 2x")

      .time
        .tick(v-for="t in time" :key="t" :style="tickStyle")
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'
import CopiePouvoirEnCours from '@/components/CopiePouvoirEnCours.vue'
import Tete1 from './DePosselTete1.vue'
import Tete3 from './DePosselTete3.vue'

const DURATION = 20 // secondes

export default {
  name: 'VuePouvoirDePossel',

  components: {
    MenuButton, Tete1, Tete3, CopiePouvoirEnCours
  },

  computed: {
    tickStyle() {
      return {
        height: `calc(100% / ${DURATION} - 6px)`
      }
    },
    head() {
      return this.heads[this.headIndex]
    },
    solvedCount() {
      return this.heads.filter(h => h.solved).length
    }
  },

  mounted() {
    this.interval = setInterval(() => {
      if (!this.personnageOk) {
        if (this.time) {
          this.time -= 1
        } else {
          this.nextHead()
        }
      }
    }, 1000)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  data () {
    return {
      time: DURATION,
      heads: [
        { index: 1, solved: false, selected: [], solution: ['sourcil', 'nez', 'yeux', 'oreille_gauche', 'oreille_droite', 'bouche'] },
        { index: 2, solved: false, selected: [], solution: ['bouche', 'oreille_droite', 'yeux', 'nez', 'oreille_gauche', 'sourcil'] }
      ],
      headIndex: 0,
      solved: false,
      personnageOk: false
    }
  },

  methods: {
    selectHeadElement(element) {
      this.$sound('click')
      if (this.head.selected.length === 6) {
        this.head.selected = [element]
      } else if (this.head.selected.indexOf(element) === -1) {
        this.head.selected.push(element)
        if (this.head.selected.length === this.head.solution.length) {
          this.head.solved = this.head.solution.filter((s, i) => s === this.head.selected[i]).length === this.head.solution.length
          this.error = !this.head.solved
          if (this.head.solved) {
            this.personnageOk = true
            this.$sound('ok')
            setTimeout(() => this.nextHead(), 2000)
          } else {
            this.$sound('error')
            setTimeout(() => this.clearSelection(), 1000)
          }
        }
      }
    },

    nextHead() {
      this.personnageOk = false
      this.time = DURATION
      this.clearSelection()
      if (this.solvedCount < 2) {
        do {
          if (this.headIndex === 1) this.headIndex = 0
          else this.headIndex += 1
        } while (this.head.solved)
      } else {
        clearInterval(this.interval)
        this.solved = true
      }
    },

    clearSelection() {
      this.head.selected = []
      this.error = false
    }
  }
}
</script>

<style lang="less">
@keyframes pouce {
  from {
    transform: scale(0.2)
  }
  25% {
    transform: scale(1.4)
  }
  30% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(5);
    opacity: 0;
  }
}
.vue-pouvoir-possel {
  #menu-button {
    right: 38px;
    top: 44px;
  }
  .tete {
    position: absolute;
    top: 210px;
    left: 110px;
    height: 800px;
  }

  .text-block.progress {
    bottom: 48px;
    top: auto;
    right: 200px;
    left: auto;
  }
  .text-block.titre {
    top: 48px;
    left: 456px;
    width: 1270px;
  }

  .personnage-ok {
    position: absolute;
    top: 280px;
    left: 660px;
    width: 789px;
    height: 646px;
    animation: pouce 2000ms forwards;
    transform-origin: center;
  }

  @keyframes selection-items-error {
    from {
      background: rgba(0,0,0,0.1);
    }
    to {
      background: rgb(255,0,0);
    }
  }

  .selection {
    position: absolute;
    left: 28px;
    top: 48px;
    width: 374px;
    height: 880px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .selection-item {
      margin: 10px 0;
      padding: 20px;
      background: rgba(0,0,0,0.1);
      border-radius: 20px;
      img {
        max-height: 100px;
      }
    }

    &.error {
      .selection-item {
        animation: selection-items-error 150ms;
        animation-iteration-count: 3;
        animation-fill-mode: forwards;
      }
    }
  }

  .text-block.clear-button {
    bottom: 48px;
    top: auto;
    width: 334px;
    left: 48px;
    transition: opacity 250ms ease;
    &.hidden {
      opacity: 0;
    }
  }

  .time {
    position: absolute;
    left: 1800px;
    top: 150px;
    width: 68px;
    height: 885px;
    display: flex;
    flex-direction: column-reverse;

    .tick {
      box-sizing: border-box;
      background: white;
      border: 5px solid black;
      margin: 3px 0;
    }
  }
}
</style>
